import React, {createRef, useContext, useEffect} from "react";
import SelectField from "../../FormFields/SelectField";
import {useFormikContext} from "formik";
import NumberField from "../../FormFields/NumberField";
import InputField from "../../FormFields/InputField";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import InputRefField from "../../FormFields/InputRefField";
import MantineMonthField from "../../FormFields/MantineMonthField";
import {ZipCodeFieldPackage} from "../../../ZipCodeFieldPackage";
import {Grid} from "@mantine/core";
import AutomotorContext from "../../../../context/products/AutomotorContext/AutomotorContext";
import {findSelectedLabel} from "../../../findSelectedLabel";

export default function VehiculoForm(props) {
    const {
        formField: {
            vehiculo: {
                marca, anio, modelo, version, tieneGnc, valorGnc, ceroKm
            },
            cliente: {
                nombre,
                apellido,
                telefono,
                email,
                codigosLocalidad,
                codigoPostal,
                idProvincia
            },
            configuracionAvanzada: {
                Zurich: {
                    mesAnioFacturacion
                }
            },

        },
        localidadOptions,
        setLocalidadOptions,
        provinciasOptions,
        setProvinciasOptions,
    } = props;
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();

    const {
        marcas,
        anios,
        modelos,
        versiones,
        updateModels,
        updateVersions,
        isLoadingModels,
        isLoadingVersions,
        vehicleDetails
    } = useContext(AutomotorContext);


    const gnc_options = [
        {value: "true", label: "Si"},
        {value: "false", label: "No"},
    ];

    const cerokm_options = [
        {value: 'true', label: 'Si'},
        {value: 'false', label: 'No'}
    ];

    useEffect(() => {
        setFieldValue(`vehiculo.${modelo.name}`, '', false);
        setFieldValue(`vehiculo.${version.name}`, '', false);
        if (values.vehiculo.anio !== '') {
            const selectedMarca = values.vehiculo.marca;
            const marcaAuto = typeof selectedMarca === 'string' ? parseInt(selectedMarca.split('-')[0]) : selectedMarca
            updateModels(marcaAuto, values.vehiculo.anio);
        }
    }, [values.vehiculo.anio, values.vehiculo.marca]);

    useEffect(() => {
        setFieldValue(`vehiculo.${version.name}`, '', false);
        if (values.vehiculo.modelo !== '') {
            const selectedMarca = values.vehiculo.marca;
            const marcaAuto = typeof selectedMarca === 'string' ? parseInt(selectedMarca.split('-')[0]) : selectedMarca
            updateVersions(marcaAuto, values.vehiculo.anio, values.vehiculo.modelo);
        }
    }, [values.vehiculo.modelo]);

    return (
        <React.Fragment>
            <Grid align="center">
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${marca.name}`}
                        label={marca.label}
                        data={marcas}
                        withAsterisk
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_marca: findSelectedLabel(marcas, value)
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${anio.name}`}
                        label={anio.label}
                        data={anios}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${modelo.name}`}
                        label={modelo.label}
                        loadingData={isLoadingModels}
                        data={modelos}
                        withAsterisk
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_modelo: findSelectedLabel(modelos, value)
                            }
                        }}
                        clearable
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${version.name}`}
                        label={version.label}
                        loadingData={isLoadingVersions}
                        data={versiones}
                        withAsterisk
                        onChangeAction={value => {
                            vehicleDetails.current = {
                                ...vehicleDetails.current,
                                detalle_version: findSelectedLabel(versiones, value)
                            }
                        }}
                        clearable
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${ceroKm.name}`}
                        label={ceroKm.label}
                        data={cerokm_options}
                        withAsterisk
                    />
                </Grid.Col>
                {values.vehiculo.ceroKm === 'true' &&
                    <Grid.Col xs={12} sm={6}>
                        <MantineMonthField
                            name={`configuracionAvanzada.Zurich.${mesAnioFacturacion.name}`}
                            label={mesAnioFacturacion.label}
                            withAsterisk
                        />
                    </Grid.Col>
                }
                <Grid.Col xs={12} sm={6}>
                    <SelectField
                        name={`vehiculo.${tieneGnc.name}`}
                        label={tieneGnc.label}
                        data={gnc_options}
                        withAsterisk
                    />
                </Grid.Col>
                {values.vehiculo.tieneGnc === 'true' && (
                    <Grid.Col xs={12} sm={6}>
                        <NumberField
                            name={`vehiculo.${valorGnc.name}`}
                            label={valorGnc.label}
                            data={anios}
                            withAsterisk
                        />
                    </Grid.Col>
                )}
                <ZipCodeFieldPackage
                    localidadField={codigosLocalidad}
                    localidadSize={6}
                    codigoPostalField={codigoPostal}
                    codigoPostalSize={6}
                    provinciaField={idProvincia}
                    provinciaSize={6}
                    localidadOptions={localidadOptions}
                    setLocalidadOptions={setLocalidadOptions}
                    provinciasOptions={provinciasOptions}
                    setProvinciaOptions={setProvinciasOptions}/>

                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${nombre.name}`}
                        label={nombre.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${apellido.name}`}
                        label={apellido.label}
                        withAsterisk
                    />
                </Grid.Col>

                <Grid.Col xs={12} sm={6}>
                    <div className="field">
                        <PhoneInput
                            defaultCountry="AR"
                            ref={ref}
                            inputComponent={InputRefField}
                            name={`cliente.${telefono.name}`}
                            label={telefono.label}
                            withAsterisk
                            value={values.cliente.telefono}
                            onChange={(e) => {
                                setFieldValue(`cliente.${telefono.name}`, e)
                            }}
                        />
                    </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${email.name}`}
                        label={email.label}
                        withAsterisk
                    />
                </Grid.Col>
            </Grid>
        </React.Fragment>
    );
}
