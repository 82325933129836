import React from 'react';
import {useFormikContext} from 'formik';
import {Select} from "@mantine/core";
import {jsonFlatten} from "../../Utils/jsonFlatten";
import LoadingData from "../../LoadingData";

const SelectField = (props) => {
    const {label, name, data, loadingData, onChangeAction, ...rest} = props;
    const {values, errors, touched, setFieldValue, setFieldTouched} = useFormikContext()

    const handleErrorText = () => {
        if (jsonFlatten(errors)[name] && jsonFlatten(touched)[name]) {
            return jsonFlatten(errors)[name];
        }
    }

    const handleChange = (v) => {
        setFieldTouched(name, true)
        setFieldValue(name, v)
        if (onChangeAction) onChangeAction(v)
    }

    return (
            <Select
                data={data}
                label={label}
                value={jsonFlatten(values)[name]}
                nothingFound={loadingData ? <LoadingData/> : "Sin resultados"}
                searchable
                onChange={(v) => handleChange(v)}
                radius="md"
                size="md"
                error={handleErrorText()}
                classNames={{
                    wrapper: 'mantine__field__wrapper',
                    label: 'mantine__field__label',
                    input: 'mantine__field__field',
                    required: 'mantine__field__required',
                    error: 'mantine__field__error',
                    item: 'mantine__field__item',
                    rightSection: 'mantine__field__rightSection',
                    dropdown: props.notShowData && 'mantine__field__dropdownNotVisible',
                }}
                {...rest}
            />
    );
};

export default SelectField;
