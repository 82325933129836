import formModel from "./formModel";
import moment from "moment";

const {
    formField: {
        descuentoModelo,
        descuentoEspecial,
        frecuenciaPago,
        cuotas,
        vigencia,
        situacionImpositiva,
        scoring: {
            edadConductores,
            garage,
            kmsAnuales
        },
        despachoElectronico,
        vigenciaHasta,
        infoDePago: {
            numeroCbu,
            marcaTarjeta,
            numeroTarjeta,
            vencimientoTarjeta,
        },
        formaDePago, vigenciaDesde,
        cliente: {
            sexo,
            numero,
            calle,
            opcional,
            codigosLocalidad,
            codigoPostal,
            idProvincia,
            codigoArea,
            telefono,
            email,
            situacionIva,
            cuit,
            nombre,
            apellido,
            fechaNacimiento,
            dni,
            personaJuridica,
            lugarNacimiento,
            categoriaMonotributo,
            actividadPrincipal,
            rol,
            estadoCivil,
        },
        emailDespachoElectronico,
        moduloCobertura,
        configuracionAvanzada: {
            sumaAseguradaVehiculo,
            codigos,
            RUS: {
                usoVehiculoRUS,
                tipoVigencia,
                codRUSLocalidad
            },
            Sancor: {
                codSancorLocalidad,
                fechaCotizacion
            },
            Zurich: {
                codZurichLocalidad,
                idViaCobro,
                mesAnioFacturacion
            }
        },
        vehiculo: {
            marca,
            modelo,
            anio,
            tipoUso,
            valor,
            tieneGnc,
            valorGnc,
            valorAdicional,
            usoVehiculo,
            ceroKm,
            equipoDeRastreo,
            gnc: {
                descripcionGnc,
                valorTotal,
                numeroRegulador,
                cilindros: {marcaCilindro, numeroCilindro},
                marcaRegulador,
            },
            patente,
            motor,
            chasis,
            inspeccionPrevia: {
                comentariosInspeccion,
                aspectoChapa,
                aspectoGeneral,
                aspectoNeumaticos,
                aspectoPintura,
                aspectoTapizado,
                colorTapizado,
                colorVehiculo,
                estadoCristales,
                resultadoInspeccion,
                kilometraje,
                fechaInspeccion,
                tipoCombustible,
                tipoLlantas,
                tipoPintura,
                tipoTapizado,
                archivos,
                daniosPreexistentes
            },
        },
        comentariosSolicitud,
        ajusteAutomatico,
        coberturas: {
            sumaAsegurada,
            selectedCobertura
        },
        detalleEquipoElectronico: {
            descripcionElectronico,
            codigoGrupoElectronico,
            sumaAseguradaElectronico,
            codigoElectronico
        },
        detalleBicicletas: {
            descripcionBicicletas,
            codigoGrupoBicicletas,
            sumaAseguradaBicicletas,
            codigoBicicletas,
        },
        tamanioVivienda,
        tipoVivienda,
    }
} = formModel;

const clienteInitialValues =
    {
        [rol.name]: '2',
        [personaJuridica.name]: '0',
        [dni.name]: '',
        [cuit.name]: '',
        [nombre.name]: '',
        [apellido.name]: '',
        [fechaNacimiento.name]: '',
        [estadoCivil.name]: '2',
        [sexo.name]: '',
        [numero.name]: '',
        [calle.name]: '',
        [opcional.name]: '',
        [codigosLocalidad.name]: '',
        [codigoPostal.name]: '',
        [idProvincia.name]: '',
        [codigoArea.name]: '',
        [telefono.name]: '',
        [email.name]: '',
        [situacionIva.name]: '4',
        [categoriaMonotributo.name]: '1',
        [actividadPrincipal.name]: '10001',
        [lugarNacimiento.name]: 'Cordoba'
    };

const vehiculoInitialValues = {
    [patente.name]: '',
    [motor.name]: '',
    [chasis.name]: '',
    [marca.name]: '',
    [modelo.name]: '',
    [anio.name]: '',
    [tipoUso.name]: '',
    [valor.name]: '',
    [valorAdicional.name]: 0,
    [usoVehiculo.name]: '2',
    [tieneGnc.name]: 'false',
    [valorGnc.name]: 200000,
    [ceroKm.name]: 'false',
    [equipoDeRastreo.name]: '',
    gnc: {
        [descripcionGnc.name]: '',
        [marcaRegulador.name]: '',
        [numeroRegulador.name]: '',
        cilindros: [
            {
                [marcaCilindro.name]: '',
                [numeroCilindro.name]: '',
            },
        ],
        [valorTotal.name]: '',
    },
    inspeccionPrevia: {
        [comentariosInspeccion.name]: '',
        [fechaInspeccion.name]: moment().format('YYYY-MM-DD'),
        [tipoCombustible.name]: '1',
        [aspectoGeneral.name]: '2',
        [estadoCristales.name]: 'SinProblemas',
        [resultadoInspeccion.name]: '1',
        [kilometraje.name]: '15000',
        [aspectoPintura.name]: '2',
        [tipoPintura.name]: '2',
        [aspectoChapa.name]: '2',
        [aspectoNeumaticos.name]: '2',
        [aspectoTapizado.name]: '2',
        [colorTapizado.name]: '13',
        [tipoTapizado.name]: '2',
        [colorVehiculo.name]: 'A declarar',
        [tipoLlantas.name]: '2',
        [archivos.name]: [],
        [daniosPreexistentes.name]: false,
    },
};

const motovehiculoInitialValues = {
    [patente.name]: '',
    [motor.name]: '',
    [chasis.name]: '',
    [marca.name]: '',
    [modelo.name]: '',
    [anio.name]: '',
    [tipoUso.name]: '',
    [valor.name]: '',
    [valorAdicional.name]: '0',
    [usoVehiculo.name]: '2',
    [ceroKm.name]: 'false',
    [equipoDeRastreo.name]: '',
    inspeccionPrevia: {
        [comentariosInspeccion.name]: '',
        [fechaInspeccion.name]: moment().format('YYYY-MM-DD'),
        [archivos.name]: [],
        [daniosPreexistentes.name]: false,
    },
};

const polizaIntialValues = {
    [frecuenciaPago.name]: '5',
    [cuotas.name]: '1',
    [ajusteAutomatico.name]: '',
    [formaDePago.name]: '',
    [vigenciaDesde.name]: moment().toDate(),
    [vigenciaHasta.name]: moment(moment().add(1, 'year').calendar(), 'MM/DD/YYYY' ).toDate(),

    [comentariosSolicitud.name]: '',
    infoDePago: {
        [numeroCbu.name]: '',
        [marcaTarjeta.name]: '',
        [numeroTarjeta.name]: '',
        [vencimientoTarjeta.name]: '',
    },
}

const coberturasInitialValues =
    {
        1: {[sumaAsegurada.name]: 0},
        2: {[sumaAsegurada.name]: 0},
        17: {[sumaAsegurada.name]: 0},
        22: {[sumaAsegurada.name]: 0},
        35: {[sumaAsegurada.name]: 0},
        38: {[sumaAsegurada.name]: 0},
    };

const coberturasAdicionalesInitialValues =
    {
        26: {
            [sumaAsegurada.name]: 0,
            [selectedCobertura.name]: false,
        },
        36: {
            [sumaAsegurada.name]: 0,
            [selectedCobertura.name]: false,
        },
        37: {
            [sumaAsegurada.name]: 0,
            [selectedCobertura.name]: false,
        },
        48: {
            [sumaAsegurada.name]: 0,
            [selectedCobertura.name]: false,
        },
    };

const detallesInitialValues = {
    detalleEquipoElectronico: [
        {
            [descripcionElectronico.name]: "",
            [sumaAseguradaElectronico.name]: "",
            [codigoElectronico.name]: 201,
            [codigoGrupoElectronico.name]: 34,
        },
    ],
    detalleBicicletas: [
        {
            [descripcionBicicletas.name]: "",
            [sumaAseguradaBicicletas.name]: "",
            [codigoBicicletas.name]: 209,
            [codigoGrupoBicicletas.name]: 31,
        },
    ],
}

const customerCombinadoInitialValues = {
    ...polizaIntialValues,
    [tamanioVivienda.name]: "Mediana",
    [tipoVivienda.name]: "",
    [codigosLocalidad.name]: "",
    [descuentoEspecial.name]: 20,
    coberturas: coberturasInitialValues,
    coberturasAdicionales: coberturasAdicionalesInitialValues,
    cliente: clienteInitialValues,
    archivosBici: [],
    archivosElectronicos: [],
    ...detallesInitialValues,
};

const customerMicrosegurosInitialValues = {
    [tamanioVivienda.name]: "Chica",
    [tipoVivienda.name]: "1022",
    [codigosLocalidad.name]: "",
    [descuentoEspecial.name]: 20,
    coberturasAdicionales: coberturasAdicionalesInitialValues,
    ...polizaIntialValues,
    [descuentoEspecial.name]: 20,
    cliente: clienteInitialValues,
    archivosBici: [],
    archivosElectronicos: [],
    ...detallesInitialValues,
};

/* Default configuration
* codigos
* tipoVigencia
* cuotas
* ajusteAutomatico
* usoVehiculoRUS / usoVehiculo
* vigenciaDesde
* vigenciaHasta
*
* User modifies
* codRUSLocalidad
* codSancorLocalidad
*/
const automotorConfiguracionAvanzadaInitialValues = {
    RUS: {
        [codRUSLocalidad.name]: '',
        [tipoVigencia.name]: 'SEMESTRAL',
        [cuotas.name]: '1',
        [ajusteAutomatico.name]: 0,
        [sumaAseguradaVehiculo.name]: 0,
        [vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
        [vigenciaHasta.name]: moment().add(6, "M").format("YYYY-MM-DD"),
        [usoVehiculoRUS.name]: 'PARTICULAR',
        [codigos.name]: '',
    },
    Sancor: {
        [codSancorLocalidad.name]: '',
        [vigencia.name]: '1',
        [frecuenciaPago.name]: '5',
        [cuotas.name]: '1',
        [ajusteAutomatico.name]: '0',
        [sumaAseguradaVehiculo.name]: 0,
        [usoVehiculo.name]: '2',
        [fechaCotizacion.name]: moment().format('YYYY-MM-DD'),
        [codigos.name]: '',
    },
    Zurich: {
        [codZurichLocalidad.name]: '',
        [idViaCobro.name]: "7",
        [mesAnioFacturacion.name]: '',
        [vigenciaDesde.name]: moment().format('YYYY-MM-DD'),
        [vigenciaHasta.name]: moment().add(1, "Y").format("YYYY-MM-DD"),
        [codigos.name]: '',
    }
}

const customerAutomotorInitialValues = {
    configuracionAvanzada: {...automotorConfiguracionAvanzadaInitialValues},
    cliente: clienteInitialValues,
    [codigosLocalidad.name]: '',
    [codigoPostal.name]: '',
    [idProvincia.name]: '',
    ...polizaIntialValues,
    [emailDespachoElectronico.name]: '',
    [despachoElectronico.name]: 'false',
    [moduloCobertura.name]: '',
    [descuentoModelo.name]: 0,
    [descuentoEspecial.name]: 0,
    scoring: {
        [garage.name]: true,
        [edadConductores.name]: false,
        [kmsAnuales.name]: false,
    },
    bienesAsegurados: [],
    vehiculo: vehiculoInitialValues,
    [vigencia.name]: "1",
    [valorAdicional.name]: 0,
    [situacionImpositiva.name]: "4",
    [personaJuridica.name]: "0",
    [frecuenciaPago.name]: "5",
};

const customerMotovehiculoInitialValues = {
    configuracionAvanzada: {...automotorConfiguracionAvanzadaInitialValues},
    cliente: clienteInitialValues,
    [codigosLocalidad.name]: '',
    ...polizaIntialValues,
    [emailDespachoElectronico.name]: '',
    [despachoElectronico.name]: 'false',
    [moduloCobertura.name]: '',
    [descuentoModelo.name]: 0,
    [descuentoEspecial.name]: 0,
    scoring: {
        [garage.name]: true,
        [edadConductores.name]: false,
        [kmsAnuales.name]: false,
    },
    bienesAsegurados: [],
    vehiculo: motovehiculoInitialValues,
    [vigencia.name]: "1",
    [valorAdicional.name]: "0",
    [situacionImpositiva.name]: "4",
    [personaJuridica.name]: "0",
    [frecuenciaPago.name]: "5",
};

export {
    customerMicrosegurosInitialValues,
    customerCombinadoInitialValues,
    customerAutomotorInitialValues,
    customerMotovehiculoInitialValues
}