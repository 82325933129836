import React, {Fragment, useContext, useState} from 'react';
import {Formik, Form} from 'formik';

import InformacionForm from './Forms/InformacionForm';
import CoberturasForm from './Forms/CoberturasForm';

import {customerCombinadoValidation} from '../FormsModel/validationSchema'
import {customerCombinadoInitialValues} from "../FormsModel/formInitialValues";
import formModel from '../FormsModel/formModel';

import CotizacionSuccess from "./CotizacionSuccess";
import EmisionSuccess from "./EmisionSuccess";
import Loading from "../../Loading";
import axios from 'axios';

import ProgressBar from "../../ProgressBar";
import PolizaForm from "./Forms/PolizaForm";
import SolicitudForm from './Forms/SolicitudForm';
import ClienteForm from './Forms/ClienteForm';
import ClienteDirectoContext from "../../../context/ClienteDirectoContext";
import moment from "moment";
import { ReactComponent as IconHome } from '../../../assets/products/hogar.svg';
import {formatPhoneNumberIntl} from "react-phone-number-input";
import {fillAdvancedConfigByCompany} from "./AuxFunctions/fillAdvancedConfigByCompany";
import {fillCoberturasValues} from "./AuxFunctions/fillCoberturasValues";
import {newFillEquipoElectronicoValues} from "./AuxFunctions/newFillEquipoElectronicoValues";
import { buildLocationCode } from '../../Utils/buildLocationCode';

const steps = ['Información', 'Coberturas', 'Resumen', 'Cliente', 'Póliza', 'Solicitud'];
const {formField} = formModel;


function _renderStepContent(step, cotizacionResponse, finalValues, setFinalValues, emisionResponse, setEmisionResponse, setActiveStep, isFirstQuotation, modifiedSumas, setModifiedSumas) {
    switch (step) {
        case 0:
            return <InformacionForm formField={formField} setFinalValues={setFinalValues} isFirstQuotation={isFirstQuotation} modifiedSumas={modifiedSumas}/>
        case 1:
            return (<CoberturasForm formField={formField} setModifiedSumas={setModifiedSumas}/>);
        case 2:
            return <div style={{margin: "16px"}}>
                {(cotizacionResponse !== null && finalValues !== null)
                    ?
                    <CotizacionSuccess finalValues={finalValues}
                                       cotizacionResponse={cotizacionResponse}/>
                    : (
                        <Fragment>
                            <p className="form__title">
                                Cotizando...
                            </p>
                            <Loading/>
                        </Fragment>
                    )
                }
            </div>
        case 3:
            return (<ClienteForm formField={formField}/>);
        case 4:
            return (<PolizaForm formField={formField}/>);
        case 5:
            return (<SolicitudForm formField={formField} cotResponse={cotizacionResponse}/>);
        case 6:
            return (
                <div style={{margin: "16px"}}>
                    {(emisionResponse !== null && finalValues !== null)
                        ? <EmisionSuccess finalValues={finalValues} emisionResponse={emisionResponse}
                                          setActiveStep={setActiveStep} setEmisionResponse={setEmisionResponse}/>
                        : (
                            <Fragment>
                                <p className="form__title">
                                    Procesando el pedido...
                                </p>
                                <Loading/>
                                <p className="form__text--center">
                                    Esto puede demorar hasta 60 segundos.
                                </p>
                            </Fragment>
                        )
                    }
                </div>
            );
        default:
            return <div>Not Found</div>;
    }
}

export default function CheckoutPage() {
    // TODO: para un mejor entendimiento del codigo se recomienda cambiar activeStep a useRef
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = customerCombinadoValidation[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const [cotizacionResponse, setCotizacionResponse] = useState(null);
    const [emisionResponse, setEmisionResponse] = useState(null);
    const [finalValues, setFinalValues] = useState(null);
    const [isFirstQuotation, setIsFirstQuotation] = useState(true);
    const [modifiedSumas, setModifiedSumas] = useState(null);
    const {activeProducts, idRowPaginasPolkista, companiesDict} = useContext(ClienteDirectoContext);
    const currentProduct = 'hogar'

    async function _cotizarForm(values, actions) {

        let jsonData = {
            "idCanalDigital": idRowPaginasPolkista,
        }
        let url = process.env.REACT_APP_BACK_SERVER;

        url += '/newcotizar/hogar';

        const companiesToQuote = activeProducts[currentProduct]['codigos_aseguradoras'].map((el) => el['id_aseguradora'])
        const companiesNames = companiesToQuote.map(company => Object.keys(companiesDict).find(key => companiesDict[key].id === company));
        const locationCodes = await buildLocationCode(companiesNames, values.codigosLocalidad);
        const advancedConfig = fillAdvancedConfigByCompany(companiesToQuote, values, activeProducts[currentProduct]['codigos_aseguradoras'])

        jsonData = {
            ...jsonData,
            "coberturas": Object.keys(values.coberturas).map((cobertura) => {
                return ({
                    numeroCobertura: cobertura,
                    sumaAsegurada: values.coberturas[cobertura].sumaAsegurada
                })
            }),
            "coberturasAdicionales": Object.keys(values.coberturasAdicionales).map((cobertura) => {
                if (values.coberturasAdicionales[cobertura].selectedCobertura) {
                    return ({
                        numeroCobertura: cobertura,
                        sumaAsegurada: values.coberturasAdicionales[cobertura].sumaAsegurada
                    })
                }
            }).filter((cobertura) => cobertura !== undefined),
            "companies": companiesToQuote,
            "parametrosAdicionales": {
                ...advancedConfig
            },
            "nombreLocalidad": values.nombreLocalidad,
            "origen": "POLKO",
            "tamanioVivienda": values.tamanioVivienda,
            "tipoVivienda": values.tipoVivienda,
            "codigosLocalidad": values.codigosLocalidad,
            "codigosLocalidades": locationCodes,
            "idProvincia": values.idProvincia,
            "codigoPostal": values.codigoPostal,
            //TODO: In order to make the producto multi-company,
            // use configuracionAvanzada instead of parametrosAdicionales
            "configuracionAvanzada": {
                "Sancor": {
                    "descuento": values.descuentoEspecial,
                    "codSancorLocalidad": advancedConfig.codSancorLocalidad
                }
            },
            "cliente": {
                "nombre": values.cliente.nombre,
                "apellido": values.cliente.apellido,
                "telefono": values.cliente.telefono,
                "email": values.cliente.email
            },
        }

        axios.post(url, {...jsonData, ...{saveToDatabase: true}}).then(res => {
            setFinalValues(jsonData);

            setCotizacionResponse({
                ...res.data.Sancor, //Today only Sancor is used
                "idCotizar": res.data.idCotizar
            });
            actions.setSubmitting(false);
        }).catch(() => {
            setFinalValues(jsonData);
            setCotizacionResponse("Error");
            actions.setSubmitting(false);
        });

    }

    async function _emitirForm(values, actions) {
        let url = process.env.REACT_APP_BACK_SERVER;

        let coberturasValues = fillCoberturasValues(values)

        let jsonData = {
            "idCanalDigital": idRowPaginasPolkista,
            "operacionCliente": true,
            "premium": cotizacionResponse.premioMensual,
            "coberturas": coberturasValues,
            "vigenciaDesde": moment(values.vigenciaDesde).format('YYYY-MM-DD'),
            "vigenciaHasta": moment(values.vigenciaHasta).format('YYYY-MM-DD'),

        }

        url += '/newemitir/hogar';
        const equiposElectronicos = newFillEquipoElectronicoValues(values)
        const companiesToQuote = activeProducts[currentProduct]['codigos_aseguradoras'].map((el) => el['id_aseguradora'])
        const companiesNames = companiesToQuote.map(company => Object.keys(companiesDict).find(key => companiesDict[key].id === company));
        const locationCodes = await buildLocationCode(companiesNames, values.codigosLocalidad);

        let _telefono = formatPhoneNumberIntl(values.cliente.telefono).split(" ")
        let codArea = _telefono[1]
        let telefono = `${_telefono[1]}${_telefono[2]}${_telefono[3]}`

        //TODO: replace hardcoded key (0) with dynamic value
        const companyCodesObj = activeProducts[currentProduct]['codigos_aseguradoras']
            .find(item => item.id_aseguradora === companiesToQuote[0]);
        const discount = companyCodesObj['descuentos']['general']

        jsonData = {
            ...jsonData,
            "company": "Sancor",
            "idCotizacion": cotizacionResponse.idCotizar,
            "tipoVivienda": values.tipoVivienda,
            "tamanioVivienda": values.tamanioVivienda,
            "formaDePago": values.formaDePago,
            "infoDePago": {
                ...values.infoDePago,
            },
            "codigoPostal": values.codigoPostal,
            "clientes": [
                {
                    "rol": values.cliente.rol,
                    "tipoPersona": values.cliente.personaJuridica,
                    "cuit": values.cliente.cuit,
                    "nombre": values.cliente.nombre,
                    "apellido": values.cliente.apellido,
                    "sexo": values.cliente.sexo,
                    "calle": values.cliente.calle,
                    "numero": values.cliente.numero,
                    "opcional": values.cliente.opcional,
                    "email": values.cliente.email,
                    "situacionImpositiva": values.cliente.situacionIva,
                    "dni": values.cliente.dni.toString(),
                    "codigoPostal": values.codigoPostal,
                    "codigosLocalidad": values.codigosLocalidad,
                    "fechaNacimiento": moment(values.cliente.fechaNacimiento).format('YYYY-MM-DD'),
                    "codigoArea": codArea,
                    "telefono": telefono,
                }
            ],
            "parametrosAdicionales": {
                ...fillAdvancedConfigByCompany(companiesToQuote, values, activeProducts[currentProduct]['codigos_aseguradoras']),
                "codSancorLocalidad": locationCodes['CodigoLocalidadSancor'],
                "descuento": discount > 0 ? discount - 5 : 0,
            },
            "comentariosSolicitud": "",
            ...(equiposElectronicos
                ? { detallesAdicionales: { ...equiposElectronicos, archivos: values.archivosBici.concat(values.archivosElectronicos) } }
                : {}),
        }

        setFinalValues(jsonData);

        axios.post(url, jsonData).then(res => {
            setEmisionResponse(res.data)
            actions.setSubmitting(false);
        }).catch(() => {
            setEmisionResponse("Error")
            actions.setSubmitting(false);
        });

    }

    function _handleSubmit(values, actions) {
        actions.setSubmitting(true);
        // scrollIntoView()
        switch (activeStep) {
            case 1:
                setActiveStep(activeStep + 1);
                setIsFirstQuotation(false)
                _cotizarForm(values, actions);
                break;
            case 5:
                setActiveStep(activeStep + 1);
                _emitirForm(values, actions);
                break;
            default:
                actions.setTouched({});
                actions.setSubmitting(false);
                setActiveStep(activeStep + 1);
                break;
        }
    }

    function _handleBack() {
        if (activeStep === 2) {
            if (cotizacionResponse === "Error")
                setCotizacionResponse(null)
            setFinalValues(null)
        } else if (activeStep === 6) {
            setEmisionResponse(null)
        }
        setActiveStep(activeStep - 1);
    }

    return (
        <React.Fragment>
            <div className="form__content">
                <div className="form__circle__container">
                    <div className="form__circle">
                        <IconHome className="form__icon--design" style={{marginLeft: "4px"}}/>
                    </div>
                </div>

                <div style={{margin: "16px 0px"}}>
                    <ProgressBar activeStep={activeStep} stepTitles={steps}/>
                </div>
                <Fragment>
                    <Formik
                        initialValues={customerCombinadoInitialValues}
                        validationSchema={currentValidationSchema}
                        validateOnBlur={false}
                        onSubmit={_handleSubmit}
                    >
                        {({isSubmitting}) => (
                            <Form id={"customerCombinadoForm"}>
                                {_renderStepContent(activeStep, cotizacionResponse, finalValues, setFinalValues, emisionResponse, setEmisionResponse, setActiveStep, isFirstQuotation, modifiedSumas, setModifiedSumas)}
                                <div
                                    className="form__footer"
                                    style={{display: activeStep > 5 && emisionResponse !== "Error" && "none"}}
                                >

                                    {activeStep !== 0 && (emisionResponse === null || emisionResponse === "Error") &&(
                                        <button
                                            type="button"
                                            onClick={_handleBack}
                                            className="button__outlined"
                                            disabled={isSubmitting}
                                        >
                                            Atras
                                        </button>
                                    )}
                                    {cotizacionResponse !== "Error" && activeStep < 6 &&
                                    <button
                                        className="button"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isLastStep
                                            ? 'Emitir'
                                            : activeStep === 1
                                                ? 'Cotizar'
                                                : 'Siguiente'}
                                    </button>
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Fragment>
            </div>
        </React.Fragment>
    );
}
