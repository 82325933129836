import React, {createRef, useContext, useEffect} from "react";
import InputField from "../../FormFields/InputField";
import PhoneInput from "react-phone-number-input";
import InputRefField from "../../FormFields/InputRefField";
import {useFormikContext} from "formik";
import CuitGenerator from "../../../Utils/CuitGenerator";
import SelectField from "../../FormFields/SelectField";
import DateField from "../../FormFields/DateField";
import ClienteDirectoContext from "../../../../context/ClienteDirectoContext";

export default function TitularForm(props) {

    const {
        formField: {
            cliente: {
                nombre, apellido, telefono, email, sexo, dni, cuit, fechaNacimiento, calle, numero, opcional
            },
            infoDePago: {
                numeroCbu, marcaTarjeta, numeroTarjeta,
            },
            formaDePago,
        },
    } = props;

    const {values, setFieldValue} = useFormikContext();
    const ref = createRef();
    const {companiesDict, genericOptionsFormasDePago} = useContext(ClienteDirectoContext)

    const formasDePago = values.compania ? companiesDict[values.compania].codigoFormasDePago : {};
    const marcasTarjeta = values.compania ? companiesDict[values.compania].idsTarjeta : [];
    const filteredOptions = genericOptionsFormasDePago.filter(option =>
        formasDePago.hasOwnProperty(option.value)
    );

    const opcionesSexo = [
        {value: '1', label: 'Femenino'},
        {value: '2', label: 'Masculino'}
    ];

    useEffect(() => {
        if (values.cliente.dni) {
            if ((values.cliente.dni).length === 8 && values.cliente.sexo !== '') {
                let generatedCuit = CuitGenerator(values.cliente.dni, values.cliente.sexo)
                setFieldValue('cliente.cuit', generatedCuit)
            }
        }
    }, [values.cliente.dni, values.cliente.sexo]);

    return (
        <React.Fragment>
            <div className="structure__form structure__center">
                <InputField
                    name={`cliente.${nombre.name}`}
                    label={nombre.label}
                    withAsterisk
                />
                <InputField
                    name={`cliente.${apellido.name}`}
                    label={apellido.label}
                    withAsterisk
                />
                <SelectField
                    name={`cliente.${sexo.name}`}
                    label={sexo.label}
                    data={opcionesSexo}
                    withAsterisk
                />
                <InputField
                    name={`cliente.${dni.name}`}
                    label={dni.label}
                    withAsterisk
                />
                <InputField
                    name={`cliente.${cuit.name}`}
                    label={cuit.label}
                    withAsterisk
                />
                <DateField
                    name={`cliente.${fechaNacimiento.name}`}
                    label={fechaNacimiento.label}
                    withAsterisk
                />
                <div className="field">
                    <PhoneInput
                        defaultCountry="AR"
                        ref={ref}
                        inputComponent={InputRefField}
                        name={`cliente.${telefono.name}`}
                        label={telefono.label}
                        withAsterisk
                        value={values.cliente.telefono}
                        onChange={(e) => {
                            setFieldValue(`cliente.${telefono.name}`, e)
                        }}
                    />
                </div>
                <InputField
                    name={`cliente.${email.name}`}
                    label={email.label}
                    withAsterisk
                />
                <InputField
                    name={`cliente.${calle.name}`}
                    label={calle.label}
                    withAsterisk
                />
                <InputField
                    name={`cliente.${numero.name}`}
                    label={numero.label}
                    withAsterisk
                />
                <InputField
                    name={`cliente.${opcional.name}`}
                    label={opcional.label}
                />

                <SelectField
                    name={formaDePago.name}
                    label={formaDePago.label}
                    data={filteredOptions}
                    withAsterisk
                />
                {values.formaDePago === 'TC' && (
                    <>
                        <SelectField
                            name={`infoDePago.${marcaTarjeta.name}`}
                            label={marcaTarjeta.label}
                            data={marcasTarjeta}
                            withAsterisk
                        />
                        <InputField
                            name={`infoDePago.${numeroTarjeta.name}`}
                            label={numeroTarjeta.label}
                            withAsterisk
                        />
                    </>
                )}
                {values.formaDePago === 'CBU' && (
                    <InputField
                        name={`infoDePago.${numeroCbu.name}`}
                        label={numeroCbu.label}
                        withAsterisk
                    />
                )}
            </div>
        </React.Fragment>
    );
}
