import React from 'react';
import {useFormikContext} from 'formik';
import {DateInput} from '@mantine/dates';
import {jsonFlatten} from "../../Utils/jsonFlatten";
import moment from "moment";

const DateField = (props) => {
    const {name, label, ...rest} = props;
    const {values, errors, touched, setFieldValue, setFieldTouched} = useFormikContext()

    /**
     * If the error object has a property that matches the name of the field, and the touched object is set in true,
     * then return the error message
     * @returns The error message for the field that is being touched in formik.
     */
    const handleErrorText = () => {
        if (jsonFlatten(errors)[name] && jsonFlatten(touched)[name]) {
            return jsonFlatten(errors)[name];
        }
    }

    return (
        <div className="field">
            <DateInput
                {...rest}
                value={name.split(".").length > 1 ? values[name.split(".")[0]][name.split(".")[1]] : values[name]}
                valueFormat="DD/MM/YYYY"
                locale="es"
                onChange={(v) => setFieldValue(name, moment(v, 'DD/MM/YYYY').toDate())}
                dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
                onBlur={()=> setFieldTouched(name, true)}
                allowFreeInput
                placeholder="dd/mm/yyyy"
                allowLevelChange={false}
                inputFormat="DD/MM/YYYY"
                radius="md"
                label={label}
                error={ handleErrorText()}
                size="md"
                classNames={{
                    wrapper: 'mantine__field__wrapper',
                    label: 'mantine__field__label',
                    input: 'mantine__field__field',
                    required: 'mantine__field__required',
                    error: 'mantine__field__error',
                    rightSection: 'mantine__field__rightSection',
                }}
            />
        </div>
    );
};

export default DateField;
