import React, {createRef, useContext, useState} from 'react';
import {ErrorMessage, useFormikContext} from 'formik'
import AutoCompleteField from "../../FormFields/AutoCompleteField";
import InputField from "../../FormFields/InputField";
import PhoneInput from "react-phone-number-input";
import InputRefField from "../../FormFields/InputRefField";
import ClienteDirectoContext from "../../../../context/ClienteDirectoContext";
import {IconBike, IconDeviceLaptop, IconDeviceTablet, IconLock} from "@tabler/icons";
import {Checkbox, Grid} from "@mantine/core";
import NumberField from "../../FormFields/NumberField";
import PopOverInfo from "../../../PopOverInfo";
import { ZipCodeFieldPackage } from '../../../ZipCodeFieldPackage';

const coberturasAdicionalesIcons = {
    26: <IconLock className="form__coberturaIcon"/>,
    48: <IconBike className="form__coberturaIcon"/>,
    36: <IconDeviceTablet className="form__coberturaIcon"/>,
    37: <IconDeviceLaptop className="form__coberturaIcon"/>,
}

export default function InformacionForm(props) {
    const {
        formField: {
            cliente: {
                nombre,
                apellido,
                telefono,
                email,
                codigosLocalidad,
                codigoPostal,
                idProvincia,
            }
        },
    } = props;
    const {localidades, coberturasInfo, coberturasAdicionalesOrder} = useContext(ClienteDirectoContext);
    const {values, setFieldValue} = useFormikContext();
    const ref = createRef()

    const [localidadOptions, setLocalidadOptions] = useState([]);
    const [provinciasOptions, setProvinciasOptions] = useState([]);

    return (
        <React.Fragment>
            <Grid>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${nombre.name}`}
                        label={nombre.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${apellido.name}`}
                        label={apellido.label}
                        withAsterisk
                    />
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <div className="field">
                        <PhoneInput
                            defaultCountry="AR"
                            ref={ref}
                            inputComponent={InputRefField}
                            name={`cliente.${telefono.name}`}
                            label={telefono.label}
                            withAsterisk
                            value={values.cliente.telefono}
                            onChange={(e) => {
                                setFieldValue(`cliente.${telefono.name}`, e)
                            }}
                        />
                    </div>
                </Grid.Col>
                <Grid.Col xs={12} sm={6}>
                    <InputField
                        name={`cliente.${email.name}`}
                        label={email.label}
                        withAsterisk
                    />
                </Grid.Col>
                <>
                    <ZipCodeFieldPackage
                        localidadField={codigosLocalidad}
                        codigoPostalField={codigoPostal}
                        provinciaField={idProvincia}
                        localidadSize={6}
                        codigoPostalSize={6}
                        provinciaSize={6}
                        localidadOptions={localidadOptions}
                        provinciasOptions={provinciasOptions}
                        setLocalidadOptions={setLocalidadOptions}
                        setProvinciaOptions={setProvinciasOptions}
                    />
                </>
            </Grid>

            {coberturasAdicionalesOrder.map(cobertura => {
                return (
                    <div className="form__item--hogar" key={cobertura}>
                        <div className="structure__spaced-between">
                            <Checkbox
                                checked={values.coberturasAdicionales[cobertura].selectedCobertura}
                                classNames={{
                                    input: "checkbox"
                                }}
                                size="sm"
                                onChange={(e) => {
                                    const {checked} = e.target;
                                    setFieldValue(`coberturasAdicionales.${cobertura}`, {
                                        selectedCobertura: checked,
                                        sumaAsegurada: coberturasInfo[cobertura].sumaChica
                                    });
                                }}
                            />
                            {coberturasAdicionalesIcons[cobertura]}
                            <p className="form__text--tiny">{coberturasInfo[cobertura].descripcion}</p>
                        </div>


                        <div
                            style={{visibility: values.coberturasAdicionales[cobertura] && values.coberturasAdicionales[cobertura].selectedCobertura ? "visible" : "hidden"}}>
                            <NumberField
                                name={`coberturasAdicionales.${cobertura}.sumaAsegurada`}
                                min={coberturasInfo[cobertura]['sumaMinima']}
                                max={coberturasInfo[cobertura]['sumaMaxima']}
                            />
                        </div>
                        <div className="structure__center">
                            <PopOverInfo descripcion={coberturasInfo[cobertura].descripcionCompleta}/>
                        </div>
                    </div>
                )
            })}

            <>
                <ErrorMessage name='coberturasAdicionales'
                              render={msg => <div
                                  className="errorMessage"
                              >{msg}</div>}/>
            </>
        </React.Fragment>
    );
}
