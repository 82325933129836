import React, {useContext, useEffect, useState} from 'react';
import {Tabs} from '@mantine/core';
import CoberturaCard from "../../CoberturaCard";
import {currencyFormat} from "../../Utils/currencyFormat";
import {useFormikContext} from "formik";
import AutomotorContext from "../../../context/products/AutomotorContext/AutomotorContext";
import {CarouselWrapper} from "../../CarouselWrapper";

const descriptionDict = {
    "basico": "Además del seguro contra terceros, se cubre el robo y el incedio total y parcial",
    "completo": "Además del seguro contra terceros, se cubre el robo y el incedio total y parcial",
    "todo_riesgo": "Se cubren los daños parciales superiores a la franquicia variable que elijas"
}

function StyledTabs(props) {
    return (
        <div className="tabs__margin--inverse">
            <Tabs
                unstyled
                keepMounted={false}
                classNames={{
                    tabIcon: "tabs__icon",
                    tabsList: "tabs__list",
                }}
                {...props}
            />
        </div>

    );
}

const cardsBuilder = (_cards, _type, _finalValues, _currentProduct) => {
    return (
        _cards.map(_cardInfo => {
            let cardInfo = _cardInfo
            let description = descriptionDict[_type]

            if (_type === "todo_riesgo") {
                let premiumsArray = _cardInfo['franchises'].map(el => el.premium).reverse();
                let franchisesArray = _cardInfo['franchises'].map(el => el.franchise).reverse();
                let idsArray = _cardInfo['franchises'].map(el => el.id).reverse();

                cardInfo = {
                    "id": idsArray,
                    "compania": _cardInfo.compania,
                    "hasFranchise": true,
                    "nombre": _cardInfo.nombre,
                    "premium": premiumsArray,
                    "franchises": franchisesArray,
                    "parametrosAdicionales": _cardInfo.parametrosAdicionales,
                };
            }

            // Adjust RUS premium to consider the discount
            // It is done using displayPremium because the premium needs to keep the original value
            // otherwise on every change of the tab, the premium would be recalculated and the discount would be applied again
            if (cardInfo.compania === "RUS") {
                if (cardInfo.hasFranchise) {
                    cardInfo.displayPremium = cardInfo.premium.map(premium => Math.ceil(premium *
                        (1 - (_finalValues['configuracionAvanzada']['RUS']['descuentoEspecial'] / 100))));
                } else {
                    cardInfo.displayPremium = Math.ceil(cardInfo.premium *
                        (1 - (_finalValues['configuracionAvanzada']['RUS']['descuentoEspecial'] / 100)));
                }
            } else { // Required to use the new key
                cardInfo.displayPremium = cardInfo.premium;
            }

            return (
                    <CoberturaCard
                        finalValues={_finalValues}
                        currentProduct={_currentProduct}
                        cardInfo={{...cardInfo, "description": description}}/>
            )
        })
    )
}

const CardCarousel = ({children}) => {
    return(
        <CarouselWrapper slideSize="auto" withControls slideGap="xl"
        slidesToScroll={1}
        initialSlide={0}
        widthIndicators={false}
        withDots={false}
        draggable >
            {children}
        </CarouselWrapper>
    )
}

function CotizacionSuccess(props) {
    const {finalValues, cotizacionResponse, currentProduct} = props;
    const plans = cotizacionResponse.planCD
    const [activeTab, setActiveTab] = useState('completos');
    const {vehicleDetails} = useContext(AutomotorContext);
    const {values} = useFormikContext();

    useEffect(() => {
        if (cotizacionResponse !== "Error") {
            values.vehiculo.gnc.valorTotal = finalValues.sumaAseguradaGnc;
            values.idCotizar = cotizacionResponse.idCotizar;
        }
    }, []);

    const getTabPrice = (type) => {
        const plan = plans[type][0];
        let planInfo = plan

        if(type === "todo_riesgo") {
            let premiumsArray = plan['franchises'].map(el => el.premium).reverse();

            planInfo = {
                "compania": plan.compania,
                "hasFranchise": true,
                "premium": premiumsArray,
            };
        } else {
            return plan.displayPremium ?? plan.premium;
        }

        if (planInfo.compania === "RUS") {
            if (planInfo.hasFranchise) {
                planInfo.displayPremium = planInfo.premium.map(premium => Math.ceil(premium *
                    (1 - (finalValues['configuracionAvanzada']['RUS']['descuentoEspecial'] / 100))));
            } else {
                planInfo.displayPremium = Math.ceil(planInfo.premium *
                    (1 - (finalValues['configuracionAvanzada']['RUS']['descuentoEspecial'] / 100)));
            }
            return Math.min(...planInfo.displayPremium.map(p => parseFloat(p)));
        } else {
            return Math.min(...planInfo.premium.map(p => parseFloat(p)));
        }
    }

    return (
        <React.Fragment>
            {cotizacionResponse === "Error"
                ?
                <div className="structure__margin--topLG">
                    <p className="form__subtitle structure__center">
                        Hubo un problema al cotizar. Por favor, vuelva a intentarlo más tarde
                    </p>
                </div>
                :
                <>
                    <div className="structure__margin--top structure__column">
                        <p className="form__text--dark" style={{textAlign: "center"}}>
                            {`${vehicleDetails.current.detalle_marca} ${vehicleDetails.current.detalle_version} (${cotizacionResponse['vehicle']['year']})`}
                        </p>
                    </div>

                    <StyledTabs value={activeTab} onTabChange={setActiveTab}>
                        <Tabs.List grow position="center">
                            {cotizacionResponse['planCD']['basicos'].length > 0 &&
                                <Tabs.Tab value="basicos" className={activeTab === "basicos" ? "tabs--active" : "tabs"}>
                                    <div className="tabs__container">
                                        <p className="tabs__text">Tercero Básico</p>
                                        <p className="tabs__text">$ {currencyFormat(getTabPrice('basicos'))}</p>
                                    </div>
                                </Tabs.Tab>
                            }
                            {cotizacionResponse['planCD']['completos'].length > 0 &&
                                <Tabs.Tab value="completos"
                                          className={activeTab === "completos" ? "tabs--active" : "tabs"}>
                                    <div className="tabs__container">
                                        <p className="tabs__text">Tercero Completo</p>
                                        <p className="tabs__text">$ {currencyFormat(getTabPrice('completos'))}</p>
                                    </div>
                                </Tabs.Tab>
                            }
                            {cotizacionResponse['planCD']['todo_riesgo'].length > 0 &&
                                <Tabs.Tab value="todo_riesgo"
                                          className={activeTab === "todo_riesgo" ? "tabs--active" : "tabs"}>
                                    <div className="tabs__container">
                                        <p className="tabs__text">{"Todo Riesgo"}</p>
                                        <p className="tabs__text">Desde
                                            $ {currencyFormat(getTabPrice('todo_riesgo'))}</p>
                                    </div>
                                </Tabs.Tab>
                            }
                        </Tabs.List>

                        <div className="tabs__margin">
                                {cotizacionResponse['planCD']['basicos'].length > 0 &&
                                    <Tabs.Panel value="basicos" pt="xs">
                                        <CardCarousel>
                                            {cardsBuilder(cotizacionResponse['planCD']['basicos'], "basico", finalValues, currentProduct)}
                                        </CardCarousel>
                                    </Tabs.Panel>
                                }
                                {cotizacionResponse['planCD']['completos'].length > 0 &&
                                    <Tabs.Panel value="completos" pt="xs">
                                        <CardCarousel>
                                            {cardsBuilder(cotizacionResponse['planCD']['completos'], "completo", finalValues, currentProduct)}
                                        </CardCarousel>
                                    </Tabs.Panel>
                                }
                                {cotizacionResponse['planCD']['todo_riesgo'].length > 0 &&
                                    <Tabs.Panel value="todo_riesgo" pt="xs">
                                        <CardCarousel>
                                            {cardsBuilder(cotizacionResponse['planCD']['todo_riesgo'], "todo_riesgo", finalValues, currentProduct)}
                                        </CardCarousel>
                                    </Tabs.Panel>
                                }
                        </div>
                    </StyledTabs>
                </>
            }
        </React.Fragment>
    );
}

export default CotizacionSuccess;
