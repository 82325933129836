import React, {useState} from 'react';
import './CarouselWrapper.css';
import {Carousel} from "@mantine/carousel";
import useWindowDimensions from '../Utils/useWindowDimensions';

/***
 * @returns {JSX.Element}
 * This component wraps mantine carousel to control the amount and logic of dots displayed
 * @param props
 */

function CarouselWrapper(props) {
    const {children, initialSlide=1, align, withDots= true, dragFree= true, slideSize, withControls= false, orientation="horizontal"} = props;
    const [selected, setSelected] = useState(0);
    const alignment = align ? align : children.length === 1 ? "center" : "start";
    const {width} = useWindowDimensions();
    const isMobile = width < 768;

    const handleSelection = (idx) => {
        if (idx === children.length - 1) {
            setSelected(2)
        } else if (idx > 0) {
            setSelected(1)
        } else {
            setSelected(0)
        }
    }

    //TODO: handle case when there is only one slide
    const renderDots = () => {
        const dots = [];

        for (let i = 0; i < 3; i++) {
            dots.push(
                <button
                    type="button"
                    key={i}
                    className={`carouselWrapper__dot ${i === selected ? "carouselWrapper__dot--active" : ""}`}
                    onClick={() => handleSelection(i)}
                />
            );
        }

        return dots;
    };

    return (
        <div className='carouselWrapper'>
            <Carousel
                slideGap="xl"
                slidesToScroll={1}
                controlsOffset="md"
                withControls={withControls && !isMobile}
                withIndicators={isMobile}
                dragFree={dragFree}
                slideSize={slideSize}
                onSlideChange={(i) => handleSelection(i)}
                initialSlide={initialSlide}
                align={alignment}
                orientation={orientation}
                classNames={{
                    root: 'mantine-carouselWrapper-root',
                    viewport: 'mantine-carouselWrapper-viewport',
                    container: 'mantine-carouselWrapper-container',
                    controls: 'mantine-carouselWrapper-controls',
                    control: 'mantine-carouselWrapper-control',
                    indicators: 'mantine-carouselWrapper-indicators',
                    indicator: 'mantine-carouselWrapper-indicator',
                }}
            >
                {children.map(child => <Carousel.Slide>{child}</Carousel.Slide>)}
            </Carousel>
            {withDots && <div className="carouselWrapper__dots__container">{renderDots()}</div>}
        </div>
    );
}

export default CarouselWrapper;

