const formatBytes = (bytes) => {
    const megabytes = Number(bytes) / (1024 * 1024);

    // If result is less than 1 MB, return KB
    if (megabytes < 1) {
        const kilobytes = Number(bytes) / 1024;
        return `${Math.ceil(kilobytes)}KB`;
    }

    return `${Math.ceil(megabytes)}MB`;
};

export default formatBytes;