import React, {useContext, useState} from 'react';
import {Accordion} from '@mantine/core';
import ClienteDirectoContext from "../context/ClienteDirectoContext";

function AccordionLabel(props) {
    return (
        <p className={props.selected ? "accordion__label--selected" : "accordion__label"}>{props.label}</p>
    );
}

function AccordionDetails() {
    const [value, setValue] = useState("Seguro de Auto");
    const {activeProducts} = useContext(ClienteDirectoContext);

    return (
        <Accordion radius="md" chevronPosition="right" className="accordion" value={value} onChange={setValue}
                   classNames={{chevron: "accordion__chevron"}}
        >
            {activeProducts['vehiculo']['active'] &&
            <Accordion.Item value="Seguro de Auto">
                <Accordion.Control>
                    <AccordionLabel label={"Seguro de Auto"} selected={value === "Seguro de Auto"}/>
                </Accordion.Control>
                <Accordion.Panel>
                    <p className="accordion__subtitle">
                        Tercero básico
                    </p>
                    <p className="accordion__text">
                        Responsabilidad civil + Robo e incendio.
                    </p>
                    <p className="accordion__subtitle">
                        Tercero completo
                    </p>
                    <p className="accordion__text">
                        Responsabilidad civil + Robo e incendio total y parcial + Destrucción total + Adicionales
                        (cristales, granizo, cerraduras, etc.)
                    </p>
                    <p className="accordion__subtitle">
                        {"Todo riesgo"}
                    </p>
                    <p className="accordion__text">
                        Seguro total con franquicia.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>
            }
            {activeProducts['motovehiculo']['active'] &&
            <Accordion.Item value="Seguro de Moto">
                <Accordion.Control>
                    <AccordionLabel label={"Seguro de Moto"} selected={value === "Seguro de Moto"}/>
                </Accordion.Control>
                <Accordion.Panel>
                    <p className="accordion__subtitle">
                        Responsabilidad civil
                    </p>
                    <p className="accordion__text">
                        Seguro contra Terceros únicamente.
                    </p>
                    <p className="accordion__subtitle">
                        Tercero básico
                    </p>
                    <p className="accordion__text">
                        Además del seguro contra Terceros se cubre Incendio y Robo total.
                    </p>
                    <p className="accordion__subtitle">
                        Tercero completo
                    </p>
                    <p className="accordion__text">
                        Además del seguro contra Terceros se cubre Incendio, Robo y Accidente total.
                    </p>
                    <p className="accordion__subtitle">
                        Full
                    </p>
                    <p className="accordion__text">
                        Además del seguro contra Terceros se cubre Incendio y Robo total y parcial, y Accidente total.
                    </p>
                    <p className="accordion__subtitle">
                        {"Todo riesgo"}
                    </p>
                    <p className="accordion__text">
                        Se cubren los daños parciales superiores a la franquicia variable que elijas.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>
            }
            {activeProducts['hogar']['active'] &&
            <Accordion.Item value="Seguro de Hogar">
                <Accordion.Control>
                    <AccordionLabel label={"Seguro de Hogar"} selected={value === "Seguro de Hogar"}/>
                </Accordion.Control>
                <Accordion.Panel>
                    <p className="accordion__subtitle">
                        Incendio
                    </p>
                    <p className="accordion__text">
                        Cubre los Daños Materiales ocasionados por la acción del Fuego, Rayo o Explosión. También se
                        incluye el adicional de Huracán, Ciclón y Tornado.
                    </p>
                    <p className="accordion__subtitle">
                        Responsabilidad Civil:
                    </p>
                    <p className="accordion__text">
                        Se protege al Asegurado por reclamos de terceros que emerjan de su Responsabilidad
                        Extracontractual, como consecuencia de daños y perjuicios producidos dentro y fuera del Hogar.
                    </p>
                    <p className="accordion__subtitle">
                        Cristales
                    </p>
                    <p className="accordion__text">
                        Cubre los daños sufridos a los cristales, vidrios, espejos y demás piezas similares instalados
                        en posición vertical. Se incluyen los gastos normales de colocación.
                    </p>
                    <p className="accordion__subtitle">
                        Mobiliario y electrodomésticos:
                    </p>
                    <p className="accordion__text">
                        Cubre Robo, Hurto o su tentativa del mobiliario. Se incluyen electrodomésticos de línea blanca
                        vinculados a la cocina y limpieza del hogar. Adicionalmente, se amparan los daños que haya
                        sufrido la vivienda asegurada como consecuencia de Robo.
                    </p>
                    <p className="accordion__subtitle">
                        Aparatos electrónicos:
                    </p>
                    <p className="accordion__text">
                        Cubre a los equipos electrónicos de pérdidas o daños causados por Incendio, Robo (Excluido el
                        Hurto) y/o Daños por accidente. Se incluye el adicional de fluctuaciones eléctricas.
                        Bienes asegurables: Tv, equipos de Audio, Videograbadoras, Home Theater, Consolas de video
                        Juegos, Pc de Escritorio, Hornos, Microondas, Aire Acondicionado, Termo tanques eléctricos,
                        Cocinas, Anafes, Grupo Electrógeno, Alarma, Mecanismo de portones eléctricos, entre otros.
                    </p>
                    <p className="accordion__subtitle">
                        Adicionales:
                    </p>
                    <ul>
                        <li>
                            <p className="Bicicleta / Monopatín:">
                                Aparatos electrónicos:
                            </p>
                            <p className="accordion__text">
                                Robo de la Bicicleta y/o Monopatín dentro y/o fuera del domicilio asegurado. Se excluye
                                expresamente Hurto, Robo Parcial y Robo de accesorios.
                            </p>
                        </li>
                        <li>
                            <p className="Bicicleta / Monopatín:">
                                Notebook / Tablet:
                            </p>
                            <p className="accordion__text">
                                Cubre Incendio, Rayo, Explosión, Robo y Daños como consecuencia de accidentes dentro de
                                la República Argentina. Se excluye expresamente Hurto.
                            </p>
                        </li>
                        <li>
                            <p className="Bicicleta / Monopatín:">
                                Objetos Varios:
                            </p>
                            <p className="accordion__text">
                                Cubre las pérdidas o daños causados por Incendio, Robo y daños por accidentes en el
                                Mundo.
                                Bienes asegurables: Cámaras fotográficas, Filmadoras, Cuadros, Objetos de arte o
                                colección, Instrumentos Musicales, Micrófonos, Amplificadores, Parlantes, Potencias,
                                Consolas de audio y sonido, Relojes, Anteojos, Lentes de contacto, Joyas, Alhajas y
                                Pieles, entre otros.

                            </p>
                        </li>
                    </ul>
                    <p className="accordion__subtitle">
                        Beneficios sin costo:
                    </p>
                    <ul>
                        <li>
                            <p className="Bicicleta / Monopatín:">
                                Asistencia en Viaje a las Personas.
                            </p>
                        </li>
                        <li>
                            <p className="Bicicleta / Monopatín:">
                                Asistencia Domiciliaria.
                            </p>
                        </li>
                        <li>
                            <p className="Bicicleta / Monopatín:">
                                Llamado al Doctor.
                            </p>
                        </li>
                    </ul>
                </Accordion.Panel>
            </Accordion.Item>
            }
            {activeProducts['microseguros']['active'] &&
            <>
                <Accordion.Item value="Seguro de Bicicleta / Monopatín">
                    <Accordion.Control>
                        <AccordionLabel label={"Seguro de Bicicleta / Monopatín"}
                                        selected={value === "Seguro de Bicicleta / Monopatín"}/>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <p className="accordion__text">
                            Cubre el Robo de la Bicicleta y/o Monopatín dentro y/o fuera del domicilio asegurado. Se
                            excluye
                            expresamente Hurto, Robo Parcial y Robo de accesorios.
                        </p>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="Seguro de Notebook / Tablet">
                    <Accordion.Control>
                        <AccordionLabel label={"Seguro de Notebook / Tablet"}
                                        selected={value === "Seguro de Notebook / Tablet"}/>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <p className="accordion__text">
                            Cubre Incendio, Rayo, Explosión, Robo y Daños como consecuencia de accidentes dentro de la
                            República Argentina. Se excluye expresamente Hurto.
                        </p>
                    </Accordion.Panel>
                </Accordion.Item>
            </>
            }
            {activeProducts['asistencia_viajero']['active'] &&
                <Accordion.Item value="Seguro de Asistencia al Viajero">
                    <Accordion.Control>
                        <AccordionLabel label={"Seguro de Asistencia al Viajero"} selected={value === "Seguro de Asistencia al Viajero"}/>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <p className="accordion__subtitle">
                            Cobertura nacional e internacional
                        </p>
                        <p className="accordion__text">
                            Acceso a atención médica y asistencia las 24 horas, los 7 días de la semana, en cualquier lugar del mundo durante tu viaje.
                        </p>
                        <p className="accordion__subtitle">
                            Asistencia médica
                        </p>
                        <p className="accordion__text">
                            Cubre gastos de consultas, hospitalización, medicamentos, y atención en caso de enfermedad o accidente mientras estés en el exterior.
                        </p>
                        <p className="accordion__subtitle">
                            Adicionales:
                        </p>
                        <ul>
                            <li>
                                <p className="Seguro de Asistencia al Viajero">
                                    Repatriación sanitaria.
                                </p>
                            </li>
                            <li>
                                <p className="Seguro de Asistencia al Viajero">
                                    Pérdida de equipaje ante extravío, robo o demora.
                                </p>
                            </li>
                            <li>
                                <p className="Seguro de Asistencia al Viajero">
                                    Gastos por cancelación, reprogramación o interrupción del viaje, entre otros.
                                </p>
                            </li>
                        </ul>
                    </Accordion.Panel>
                </Accordion.Item>
            }
        </Accordion>
    );
}

export default AccordionDetails;