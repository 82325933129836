import React, {useEffect, useState} from 'react';
import {Menu} from '@mantine/core';
import "../css/styles.css";
import {IconX, IconCheck, IconArrowDown} from "@tabler/icons";
import PdfIcon from "../assets/systemIcons/PDF_icon.png";
import {useFormikContext} from "formik";

function DropzoneFile(props) {
    const {file, index, onDelete, fileFieldName = "vehiculo.inspeccionPrevia.archivos"} = props;
    const [label, setLabel] = useState(null);
    const [preview, setPreview] = useState(null);
    const {setFieldValue, errors, touched, setFieldTouched} = useFormikContext();

    const errorFileField = errors.fileFieldName;
    const touchedFileField = touched.fileFieldName;

    const handleSelectLabel = (l) => {
        setFieldValue(`${fileFieldName}.${index}.type`, l)
        setFieldTouched(`${fileFieldName}.${index}.type`, true, false);
        setLabel(l);
    }

    const handleDeleteImage = () => {
        onDelete()
        setLabel(null);
    }

    function renderFileLabel() {
        if (errorFileField &&
            errorFileField[index] &&
            touchedFileField &&
            touchedFileField[index] &&
            touchedFileField[index].type
        ) {
            return (
                <div className="dropzoneFile__label__container">
                    <p className="dropzoneFile__label--error">Es necesario agregar una etiqueta</p>
                    <IconArrowDown className="dropzoneFile__icon__picker"/>
                </div>
            );
        } else if (label) {
            return (
                <div className="dropzoneFile__label__container">
                    <p className="dropzoneFile__label--right">{label}</p>
                    <IconCheck className="dropzoneFile__icon__check"/>
                </div>
            );
        } else {
            return (
                <div className="dropzoneFile__label__container">
                    <p className="dropzoneFile__label">Etiqueta</p>
                    <IconArrowDown className="dropzoneFile__icon__picker"/>
                </div>
            );
        }
    }

    useEffect(() => {
        let objectUrl;

        if (file) {
            if (file.file.type === 'application/pdf') {
                setPreview(PdfIcon)
            } else {
                objectUrl = URL.createObjectURL(file.file)
                setPreview(objectUrl)
            }

            setLabel(file.type)
        }

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file]);


    return (
        <div className="dropzoneFile__card">
            <div className="dropzoneFile__image__container">
                {preview && <img className="dropzoneFile__image" src={preview} alt="preview_file"/>}
                <div onClick={() => handleDeleteImage()}><IconX className="dropzoneFile__icon__close"/></div>
            </div>
            <div className="dropzoneFile__menu__container">
                <Menu shadow="md" width={144} classNames={{
                    item: "dropzoneFile__menu__item"
                }}>
                    <Menu.Target>
                        <div className="dropzoneFile__target__container">
                            {renderFileLabel()}
                        </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item>
                            <p className="dropzoneFile__menu__item--custom" onClick={() => handleSelectLabel("FRENTE")}>
                                Frente
                            </p>
                        </Menu.Item>
                        <Menu.Item>
                            <p className="dropzoneFile__menu__item--custom" onClick={() => handleSelectLabel("ATRAS")}>
                                Atrás
                            </p>
                        </Menu.Item>
                        <Menu.Item>
                            <p className="dropzoneFile__menu__item--custom" onClick={() => handleSelectLabel("LATERALDERECHO")}>
                                Lateral derecho
                            </p>
                        </Menu.Item>
                        <Menu.Item>
                            <p className="dropzoneFile__menu__item--custom" onClick={() => handleSelectLabel("LATERALIZQUIERDO")}>
                                Lateral izquierdo
                            </p>
                        </Menu.Item>
                        <Menu.Item>
                            <p className="dropzoneFile__menu__item--custom" onClick={() => handleSelectLabel("AUXILIO")}>
                                Auxilio
                            </p>
                        </Menu.Item>
                        <Menu.Item>
                            <p className="dropzoneFile__menu__item--custom" onClick={() => handleSelectLabel("OTRA")}>
                                Otra
                            </p>
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </div>
        </div>
    );
}

export default DropzoneFile;