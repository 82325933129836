import React, {createRef, useContext, useEffect, useState} from "react";
import {EntradaCotizarModel} from "./EntradaCotizarModel";
import {FieldArray, useFormikContext} from "formik";
import {Grid} from "@mantine/core";
import MantineDatePickerTwoFields from "../../../../FormFields/MantineDatePickerTwoFields";
import {ReactComponent as AddPassenger} from "../../../../../../assets/systemIcons/ic_plus_filled-primary.svg";
import {ReactComponent as RemovePassenger} from "../../../../../../assets/systemIcons/ic_substract_filled-primary.svg"
import MantineNumberField from "../../../../FormFields/MantineNumberField";
import MantineSelectField from "../../../../FormFields/MantineSelectField";
import "./EntradaCotizarForm.css";
import PhoneInput from "react-phone-number-input";
import InputRefField from "../../../../FormFields/InputRefField";
import AsistenciaViajeroContext from "../../../../../../context/products/AsistenciaViajeroContext";
import MantineCheckbox from "../../../../../MantineCheckbox";
import moment from "moment";
import InputField from "../../../../FormFields/InputField";

export default function EntradaCotizarForm(props) {
    const {formField} = EntradaCotizarModel();
    const ref = createRef();
    const {
        idPais,
        nombrePais,
        vigenciaDesde,
        vigenciaHasta,
        pasajeros,
        cliente: {
            nombre,
            apellido,
            telefono,
            email
        }
    } = formField;
    const {destinos} = useContext(AsistenciaViajeroContext)
    const {values, setFieldValue} = useFormikContext();
    const [isMultipleDestinations, setIsmultipleDestinations] = useState(false);
    const [travelDaysCounter, setTravelDaysCounter] = useState(0);

    const handleDaysCounter = (range) => {
        if (range[0] && range[1]) {
            const startDate = moment(range[0]);
            const endDate = moment(range[1]);
            const days = endDate.diff(startDate, 'days') + 1;
            if (days) setTravelDaysCounter(days);
            else setTravelDaysCounter(0);
        } else setTravelDaysCounter(0);

    }
    const getDestinoLabel = (value) => {
        const destino = destinos.find((destino) => destino.value === value);
        setFieldValue(nombrePais.name, destino.label);
    }

    useEffect(() => {
        if (values.idPais) {
            getDestinoLabel(values.idPais);
        }
    }, [values.idPais])
    return (
        <Grid align="start">
            <Grid.Col xs={12} sm={6}>
                <div className="EntradaCotizarForm__destinoField">
                    <MantineSelectField
                        name={idPais.name}
                        label={idPais.label}
                        data={destinos}
                        searchable
                        withAsterisk
                        disabled={isMultipleDestinations}
                        filter={(value, item) => (item.label.toLowerCase().includes(value.toLowerCase()) && item.value !== "9999")}
                    />
                    <MantineCheckbox
                        checkedState={isMultipleDestinations}
                        onChange={(event) => {
                            setIsmultipleDestinations(event.currentTarget.checked)
                            if (event.currentTarget.checked) {
                                setFieldValue(idPais.name, "9999")
                            } else {
                                setFieldValue(idPais.name, "")
                            }
                        }}
                        label="Multiples destinos"
                    />
                </div>
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
                <div className="EntradaCotizarForm__destinoField">
                    <MantineDatePickerTwoFields
                        name={[vigenciaDesde.name, vigenciaHasta.name]}
                        label={vigenciaHasta.label}
                        minDate={new Date()}
                        withAsterisk
                        type="range"
                        onChangeAction={handleDaysCounter}
                    />
                    {travelDaysCounter > 0 &&
                        <p className="form__text--tiny">{travelDaysCounter} días de cobertura</p>}
                </div>
            </Grid.Col>
            <FieldArray name={pasajeros.name}>
                {({push, remove}) => (
                    <>
                        {values.pasajeros.map((pasajero, index) => (
                            <Grid.Col key={index} xs={3.5} span={8}>
                                <MantineNumberField
                                    name={`${formField.pasajeros.name}[${index}].edad`}
                                    label={`Edad pasajero ${index + 1}`}
                                    withAsterisk
                                    min={1}
                                    max={150}
                                    notFormat
                                />
                            </Grid.Col>
                        ))}
                        <Grid.Col xs={1.5} span={4}>
                            <div className="EntradaCotizarForm__actionButtonsContainer">
                                {values.pasajeros.length < 5 && <AddPassenger onClick={() => push({edad: ''})}
                                                                              className="iconFormat EntradaCotizarForm__actionButton"/>}
                                {values.pasajeros.length > 1 &&
                                    <RemovePassenger onClick={() => remove(values.pasajeros.length - 1)}
                                                     className="iconFormat EntradaCotizarForm__actionButton"/>}
                            </div>
                        </Grid.Col>
                    </>
                )}
            </FieldArray>
            <hr className="asistenciaViajero__hrDivider"/>
            <h1 className="asistenciaViajero__emergencyContactTitle">Información de contacto</h1>
            <Grid.Col xs={12} sm={6}>
                <InputField
                    name={`cliente.${nombre.name}`}
                    label={nombre.label}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
                <InputField
                    name={`cliente.${apellido.name}`}
                    label={apellido.label}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
                <InputField
                    name={`cliente.${email.name}`}
                    label={email.label}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col xs={12} sm={6}>
                <PhoneInput
                    defaultCountry="AR"
                    ref={ref}
                    inputComponent={InputRefField}
                    name={`cliente.${telefono.name}`}
                    label={telefono.label}
                    value={values.cliente.telefono}
                    onChange={(e) => {
                        setFieldValue(`cliente.${telefono.name}`, e)
                    }}
                />
            </Grid.Col>
        </Grid>
    );
}
